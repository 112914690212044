import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "../../api/axiosapi";

const initialState = {
  loading: false, // To indicate waiting for response from server and whats appening in the background
  users: [],
  error: "", // To indicate error message
  updateUserStatus: false,
  updateUserError: "",
  userCreationStatus: false,
};

// Generates pending, fullfilled and rejected actions
export const fetchUsers = createAsyncThunk(
  "users/fetchUsers", // Name of the action
  async () => {
    const response = await axiosPrivate.get("/api/auth/getUsers");
    return response.data;
  }
);

export const fetchUsersWithFilter = createAsyncThunk(
  "users/fetchUsersWithFilter",
  async (filter) => {
    const response = await axiosPrivate.get(
      `/api/auth/getUsersWithFilter/${filter}`
    );
    return response.data;
  }
);

export const fetchDeleteUser = createAsyncThunk(
  "users/fetchDeleteUser",
  async (id) => {
    const response = await axiosPrivate.delete(`/api/auth/deleteUser/${id}`);
    return response.data;
  }
);

export const fetchCreateUser = createAsyncThunk(
  "users/fetchCreateUser",
  async (user) => {
    const response = await axiosPrivate.post("/api/auth/signup", user);
    return response.data;
  }
);

export const fetchUpdateUser = createAsyncThunk(
  "users/fetchUpdateUser",
  async (userData) => {
    const response = await axiosPrivate.put(
      `/api/auth/updateUser/${userData.id}`,
      userData.user
    );
    return response.data;
  }
);

const apiSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUsersWithFilter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload;
      state.error = "";
    });
    builder.addCase(fetchUsersWithFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload;
      state.error = "";
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.loading = false;
      state.users = [];
      state.error = action.error.message;
    });
    builder.addCase(fetchUsersWithFilter.rejected, (state, action) => {
      state.loading = false;
      state.users = [];
      state.error = action.error.message;
    });
    builder.addCase(fetchDeleteUser.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(fetchCreateUser.pending, (state) => {
      state.userCreationStatus = true;
    });
    builder.addCase(fetchCreateUser.fulfilled, (state) => {
      state.userCreationStatus = false;
      state.error = "";
    });
    builder.addCase(fetchCreateUser.rejected, (state, action) => {
      state.error = action.error.message;
      state.userCreationStatus = false;
    });
    builder.addCase(fetchUpdateUser.pending, (state) => {
      state.updateUserStatus = true;
    });
    builder.addCase(fetchUpdateUser.fulfilled, (state) => {
      state.updateUserStatus = false;
      state.error = "";
    });
    builder.addCase(fetchUpdateUser.rejected, (state, action) => {
      state.updateUserStatus = false;
      state.updateUserError = action.error.message;
    });
  },
});

export default apiSlice.reducer;
