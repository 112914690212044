import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Box,
  Button,
  Container,
  FormControl,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import useAxiosPrivate from "../Hooks/useAxiosPrivate";
// import { createSignupMailTemplate } from "../Mail/MailTemplates";
import { normifyRole } from "./userTools";
import { fetchUpdateUser, fetchUsers } from "../../data/slices/apiSlice";
import LoadingSpinner from "../Loader/LoadingSpinner";

const roleMapping = {
  Admin: 2,
  Bureau: 1,
  Amicaliste: 0,
};

function autoCompleteUsername() {
  const currentFirstName = document.getElementById("userFirstName").value;
  const currentLastName = document.getElementById("userLastName").value;
  document.getElementById(
    "username"
  ).value = `${currentLastName[0]}${currentFirstName}`;
}

function autoCompleteForm() {
  autoCompleteUsername();
}

const containerStyle = {
  minWidth: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const formStyle = {
  minWidth: "100%",
  display: "flex",
  alignItems: "center",
};

const inputBoxStyle = {
  minWidth: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-around",
  alignItems: "center",
  gap: ".5rem",
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
  borderRadius: "10px",
  padding: ".5rem",
  margin: ".5rem",
};

function EditUserForm({ userIdToFetch, modificationType }) {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const currentModificationType = modificationType;

  const routeIsBlocked = () => {
    if (currentModificationType === "light") {
      return true;
    }
    return false;
  };

  const dispatch = useDispatch();
  const updateUserStatus = useSelector((state) => state.api.updateUserStatus);
  const roleSelector = useRef(null);
  const firstNameInput = useRef(null);
  const lastNameInput = useRef(null);
  const usernameInput = useRef(null);
  const emailInput = useRef(null);
  const passwordInput = useRef(null);
  const passwordConfirmInput = useRef(null);

  const userId = userIdToFetch; // useParams().id;
  const [user, setUser] = useState();
  const axiosPrivate = useAxiosPrivate();
  const assignRole = () => {
    roleSelector.current.selectedIndex = roleMapping[user.role];
  };
  function initUserInformation() {
    firstNameInput.current.value = user.firstName;
    lastNameInput.current.value = user.lastName;
    usernameInput.current.value = user.username;
    emailInput.current.value = user.email;
    passwordInput.current.value = "";
    passwordConfirmInput.current.value = "";
    if (!routeIsBlocked()) {
      assignRole();
    }
  }
  async function handleClick() {
    if (passwordInput.current.value === passwordConfirmInput.current.value) {
      const newUser = {
        firstName: firstNameInput.current.value,
        lastName: lastNameInput.current.value,
        username: usernameInput.current.value,
        email: emailInput.current.value,
        role: !routeIsBlocked() ? normifyRole(roleSelector.current.value) : "",
        password: passwordInput.current.value,
      };
      await dispatch(fetchUpdateUser({ user: newUser, id: userId }));
      setShowSuccessMessage(true);
      await dispatch(fetchUsers());

      setTimeout(() => {
        document.getElementById("feedbackUserCreation").textContent = "";
        setShowSuccessMessage(false);
      }, 5000);
    }
  }
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUser = async () => {
      const response = await axiosPrivate.get(`/api/auth/getUser/${userId}`, {
        signal: controller.signal,
      });
      if (isMounted) {
        setUser(response.data);
      }
    };
    getUser();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [axiosPrivate, userId]);

  useEffect(() => {
    if (user) {
      initUserInformation();
    }
  });

  return (
    <Container
      className="gestion_user--form"
      sx={containerStyle}
      component="article"
    >
      <FormControl sx={formStyle} variant="filled">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            margin: "1rem",
          }}
        >
          <Edit />
          <Typography variant="h5" component="h3">
            Modification du compte
          </Typography>
        </Box>
        <Box sx={inputBoxStyle}>
          <div className="inputBox">
            <input
              type="text"
              ref={firstNameInput}
              required
              onChange={() => autoCompleteForm()}
            />
            <label htmlFor="username">Nom </label>
          </div>
          <div className="inputBox">
            <input
              type="text"
              ref={lastNameInput}
              required
              onChange={() => autoCompleteForm()}
            />
            <label htmlFor="username">Prénom</label>
          </div>
          <div className="inputBox">
            <input type="text" ref={usernameInput} required />
            <label htmlFor="username">Nom d&apos;utilisateur</label>
          </div>
          <div className="inputBox">
            <input type="email" ref={emailInput} required />
            <label htmlFor="email">Email</label>
          </div>
          <div className="inputBox">
            <input type="password" ref={passwordInput} required />
            <label htmlFor="password">Mot de passe (Optionnel)</label>
          </div>
          <div className="inputBox">
            <input type="password" ref={passwordConfirmInput} required />
            <label htmlFor="Confirm Password">Confirmer mot de passe</label>
          </div>
          {!routeIsBlocked() ? (
            <div className="inputBox">
              <select ref={roleSelector}>
                <option value="amicaliste">Amicaliste</option>
                <option value="bureau">Bureau</option>
                <option value="admin">Admin</option>
              </select>
              <label htmlFor="role">Role</label>
            </div>
          ) : null}
        </Box>
        <p id="feedbackUserCreation" />
        {showSuccessMessage ? (
          <Alert variant="filled" severity="success">
            Modification effectuée
          </Alert>
        ) : null}
        <Button
          variant="contained"
          type="submit"
          value="Valider la modification"
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
          disabled={updateUserStatus}
        >
          {updateUserStatus === true ? <LoadingSpinner /> : null}
          <p>
            {updateUserStatus === false
              ? "Valider la modification"
              : "Modification en cours"}
          </p>
        </Button>
      </FormControl>
    </Container>
  );
}
export default EditUserForm;
