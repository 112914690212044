import React from "react";
import HorizontalNav from "../Navbar/HorizontalNav";
import logo from "../../Assets/logo_amicale.png";

function Header() {
  return (
    <header className="header__container">
      <img
        src={logo}
        alt="logo de l'amicale des sapeurs-pompiers de la ville de Châtellerault"
        className="header__logo"
      />
      <h2>Amicale des sapeurs-pompiers de Châtellerault</h2>
      <HorizontalNav />
    </header>
  );
}

export default Header;
