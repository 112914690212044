import React from "react";

function NotFound() {
  return (
    <div className="errors__notfound">
      <p>Woops, il doit y avoir une erreur : 404 - NotFound</p>
    </div>
  );
}

export default NotFound;
