import { Container } from "@mui/material";
import React from "react";
import HomeMessageCard from "./HomeMessageCard";

function HomeMessage() {
  return (
    <Container maxWidth="xl">
      <HomeMessageCard
        message={{
          title:
            "Bienvenue sur le site de l'amicale des sapeurs pompiers de Châtellerault",
          body: "Le site est actuellement en construction.",
          src: "assets/image_calendrier.jpg",
        }}
      />
    </Container>
  );
}

export default HomeMessage;
