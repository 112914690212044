import React from "react";
import { Link } from "react-router-dom";

function AdminPannelButton() {
  return (
    <Link to="/gestion/users" className="navbar__item">
      <i className="fa-solid fa-gear nav__element" />
      <span>Administration</span>
    </Link>
  );
}

export default AdminPannelButton;
