import { configureStore } from "@reduxjs/toolkit";
import orderReducer from "./slices/orderSlice";
import cartReducer from "./slices/cartSlice";
import usersReducer from "./slices/usersSlice";
import apiReducer from "./slices/apiSlice";
import mailReducer from "./slices/mailSlice";
import authReducer from "./slices/authSlice";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    order: orderReducer,
    cart: cartReducer,
    users: usersReducer,
    api: apiReducer,
    mail: mailReducer,
    auth: authReducer,
  },
});

export default store;
