import { DeleteForever } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeleteReservationById } from "../../data/slices/orderSlice";
import useRefreshReservationView from "../Hooks/useRefreshReservationView";

const buttonStyle = {
  minHeight: "48px",
  minWidth: "48px",
  color: "error.main",
  borderColor: "error.main",
};

function ButtonDeleteOrder({ order }) {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.response);

  const handleDelete = async () => {
    await dispatch(fetchDeleteReservationById(order._id));
    useRefreshReservationView(dispatch, order, userInfo);
  };
  return (
    <Tooltip title="Supprimer la réservation" placement="top">
      <Button onClick={handleDelete} sx={buttonStyle} variant="outlined">
        <DeleteForever sx={buttonStyle} />
      </Button>
    </Tooltip>
  );
}

export default ButtonDeleteOrder;
