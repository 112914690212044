import { Container } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

const mainStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px",
  width: "100%",
  minHeight: "70%",
  maxWidth: "100% !important",
};

function Home() {
  return (
    <Container component="main" sx={mainStyle}>
      <Outlet />
    </Container>
  );
}

export default Home;
