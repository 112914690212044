import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import React from "react";
import { Link } from "react-router-dom";

const iconStyle = {
  width: "2rem",
  height: "2rem",
  color: "white",
};

function Reservations() {
  return (
    <Link to="/reservations" className="navbar__item">
      <ShoppingCartCheckoutIcon sx={iconStyle} />
      <span>Réservations</span>
    </Link>
  );
}

export default Reservations;
