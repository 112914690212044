import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import React from "react";

const cardMediaStyle = {
  height: "15rem",
};

const cardHeaderStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "0.1rem",
};
function HomeMessageCard({ message }) {
  return (
    <Card component="article" elevation="false">
      <CardMedia
        image={message.src}
        alt={message.name}
        title={message.name}
        sx={cardMediaStyle}
      />
      <CardContent component="p" sx={cardHeaderStyle}>
        <Typography gutterBottom variant="h5" component="h2">
          {message.title}
        </Typography>
        <Typography variant="body1" color="text.secondary" component="h3">
          {message.body}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default HomeMessageCard;
