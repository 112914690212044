import { Container, Stack } from "@mui/material";
import React from "react";
/* import Gallery from "../Gallery/Gallery"; */
import HomeMessage from "../Gallery/HomeMessage";
import Presentation from "../Presentation/Presentation";

function Visitor() {
  return (
    <Container maxWidth="xl">
      <Stack direction={{ xs: "column", lg: "row" }} justifyContent="stretch">
        <HomeMessage />
        <Presentation />
      </Stack>
    </Container>
  );
}

export default Visitor;
