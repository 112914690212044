import React from "react";
import { Link } from "react-router-dom";
import useAuth from "../Hooks/useAuth";

function ToggleAuthButton() {
  const { auth } = useAuth();
  return (
    <div>
      {auth !== undefined && auth.role !== undefined ? (
        <a href="https://amicalespchatellerault.fr" className="navbar__item">
          <i className="fa-solid fa-arrow-right-from-bracket nav__element" />
          <span>Deconnexion</span>
        </a>
      ) : (
        <Link to="/auth" className="navbar__item">
          <i className="fa-solid fa-user nav__element" />
          <span>Se connecter</span>
        </Link>
      )}
    </div>
  );
}

export default ToggleAuthButton;
