import React from "react";
import useAuth from "../Hooks/useAuth";
import AdminPannelButton from "./AdminPannelButton";
import AgendaButton from "./AgendaButton";
import HomeButton from "./HomeButton";
import ParameterButton from "./ParameterButton";
import ReservationsButton from "./ReservationsButton";
import ToggleAuthButton from "./ToggleAuthButton";

function HorizontalNav() {
  const { auth } = useAuth();

  return (
    <nav>
      <ul>
        <li className="nav--horizontal-item">
          <HomeButton />
        </li>
        <li className="nav--horizontal-item">
          <ToggleAuthButton />
        </li>
        <li>
          {auth !== undefined && auth.role !== undefined ? (
            <AgendaButton />
          ) : null}
        </li>
        <li>
          {auth !== undefined && auth.role !== undefined ? (
            <ReservationsButton />
          ) : null}
        </li>
        <li>
          {auth !== undefined && auth.role !== undefined ? (
            <ParameterButton />
          ) : null}
        </li>
        <li>
          {auth !== undefined &&
          auth.role !== undefined &&
          auth.role === "Admin" ? (
            <AdminPannelButton />
          ) : null}
        </li>
      </ul>
    </nav>
  );
}

export default HorizontalNav;
