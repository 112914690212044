import { createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "../../api/axiosapi";

const initialState = {};

export const usersSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    callGetUsers: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state = action.payload;
    },
    callUpdateReservationsActivated: (state, action) => {
      const { userId } = action.payload;
      const { reservationsActivated } = action.payload;
      axiosPrivate
        .put(`/api/auth/updateReservationsActivated/${userId}`, {
          reservationsActivated,
        })
        .then((response) => {
          state.users = response.data;
        })

        .catch((error) => {
          throw error;
        });
    },
  },
});

// Action creators are generated for each case reducer function
export const { callGetUsers, callUpdateReservationsActivated } =
  usersSlice.actions;
export default usersSlice.reducer;
