import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "../../api/axiosapi";

const initialState = {
  cart: [],
  objects: [],
  objectTarget: {},
  objectsCategories: [],
  fetchObjectsStatus: false,
  fetchObjectsError: "",
  fetchAddObjectsStatus: false,
  fetchAddObjectsError: "",
  fetchModifyObjectStatus: false,
  fetchModifyObjectError: "",
};

// Generates pending, fullfilled and rejected actions
export const fetchObjects = createAsyncThunk(
  "object/getObjects", // Name of the action
  async () => {
    const response = await axiosPrivate.get("/api/object/getObjects");
    return response.data;
  }
);

export const fetchAddObject = createAsyncThunk(
  "object/addObject", // Name of the action
  async (object) => {
    const response = await axiosPrivate.post("/api/object/addObject", object);
    return response.data;
  }
);

export const fetchDeleteObject = createAsyncThunk(
  "object/deleteObject", // Name of the action
  async (id) => {
    const response = await axiosPrivate.delete(
      `/api/object/deleteObject/${id}`
    );
    return response.data;
  }
);

export const fetchObjectById = createAsyncThunk(
  "object/getObjectById", // Name of the action
  async (id) => {
    const response = await axiosPrivate.get(`/api/object/getObject/${id}`);
    return response.data;
  }
);

export const fetchModifyObject = createAsyncThunk(
  "object/modifyObject", // Name of the action
  async (object) => {
    const response = await axiosPrivate.put(
      `/api/object/updateObject/${object.id}`,
      object
    );
    return response.data;
  }
);

export const fetchUpdateAvailability = createAsyncThunk(
  "object/updateAvailability", // Name of the action
  async (id) => {
    const response = await axiosPrivate.put(
      `/api/object/updateAvailability/${id}`
    );
    return response.data;
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchObjects.pending, (state) => {
      state.fetchObjectsStatus = true;
    });
    builder.addCase(fetchObjects.fulfilled, (state, action) => {
      state.objects = action.payload;
      state.fetchObjectsStatus = false;
      state.fetchObjectsError = "";

      /* Get categories from objects */
      let categoriesFound = action.payload.map((object) => object.category);
      categoriesFound = [...new Set(categoriesFound)];
      categoriesFound.sort();
      state.objectsCategories = categoriesFound;
    });
    builder.addCase(fetchObjectById.fulfilled, (state, action) => {
      state.objectTarget = action.payload;
      state.fetchObjectsStatus = false;
      state.fetchObjectsError = "";
    });
    builder.addCase(fetchModifyObject.fulfilled, (state) => {
      state.fetchModifyObjectStatus = false;
      state.fetchModifyObjectError = "";
    });
    builder.addCase(fetchObjects.rejected, (state, action) => {
      state.fetchObjectsStatus = false;
      state.fetchObjectsError = action.error.message;
    });
    builder.addCase(fetchAddObject.pending, (state) => {
      state.fetchAddObjectsStatus = true;
    });
    builder.addCase(fetchAddObject.fulfilled, (state) => {
      state.fetchAddObjectsStatus = false;
      state.fetchAddObjectsError = "";
    });
    builder.addCase(fetchAddObject.rejected, (state, action) => {
      state.fetchAddObjectsStatus = false;
      state.fetchAddObjectsError = action.error.message;
    });
  },
  reducers: {
    increaseItemAmount: (state, action) => {
      const { itemToIncrease } = action.payload;
      const { amount } = action.payload;

      if (state.cart.length === 0) {
        const itemToPush = {
          id: itemToIncrease._id,
          name: itemToIncrease.name,
          quantity: amount,
        };
        state.cart.push(itemToPush);
      } else {
        const index = state.cart.findIndex(
          (itemToCompare) => itemToCompare.id === itemToIncrease._id
        );

        if (index !== -1) {
          const currentCart = state.cart;
          currentCart[index].quantity += amount;
          state.cart = currentCart;

          /* delete item if amount = 0 */
          if (currentCart[index].quantity === 0) {
            currentCart.splice(index, 1);
            state.cart = currentCart;
          }
        } else {
          const itemToPush = {
            id: itemToIncrease._id,
            name: itemToIncrease.name,
            quantity: amount,
          };
          state.cart.push(itemToPush);
        }
      }
    },
    clearCart: (state) => {
      state.cart = [];
    },
    adjustAvailableQuantity: (state, action) => {
      const reservationsGroups = action.payload;

      reservationsGroups.forEach((reservationGroup) => {
        reservationGroup.reservations.forEach((reservation) => {
          reservation.items.forEach((item) => {
            const index = state.objects.findIndex(
              (object) => object._id === item.id
            );
            if (index !== -1) {
              const currentObjects = state.objects;
              currentObjects[index].availableQuantity -= item.quantity;
              state.objects = currentObjects;
            }
          });
        });
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const { increaseItemAmount, clearCart, adjustAvailableQuantity } =
  cartSlice.actions;
export default cartSlice.reducer;
