import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosapi from "../../api/axiosapi";

const initialState = {
  response: null,
  loginStatus: false, // To indicate waiting for response from server and whats appening in the background
  error: "", // To indicate error message
};

export const fetchLogin = createAsyncThunk(
  "users/fetchLogin",
  async (userInformations) => {
    const response = await axiosapi.post("/api/auth/login", {
      username: userInformations.username,
      password: userInformations.password,
    });
    return response.data;
  }
);

const authSlice = createSlice({
  name: "login",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLogin.pending, (state) => {
      state.error = "";
      state.loginStatus = true;
    });
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.loginStatus = false;
      state.error = "";
      state.response = action.payload;
    });
    builder.addCase(fetchLogin.rejected, (state, action) => {
      state.loginStatus = false;
      state.error = action.error.message;
    });
  },
});

export default authSlice.reducer;
