import { ExpandMoreRounded } from "@mui/icons-material";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import ButtonDeleteOrder from "../Buttons/ButtonDeleteOrder";

function PersonnalOrderAccordion({ order }) {
  const currentSendingDate = new Date(order.sendingDate);
  const currentEndDate = new Date(order.endDate);
  const currentStartDate = new Date(order.startDate);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreRounded />}>
        <Typography variant="subtitle2" component="h3">
          Réservation <strong>N°{order._id}</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Typography variant="body2" component="p">
            Du {currentStartDate.toLocaleDateString("fr")} au{" "}
            {currentEndDate.toLocaleDateString("fr")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" component="p">
            Réservation faite le {currentSendingDate.toLocaleDateString("fr")}
          </Typography>
        </Box>
        <Box>
          {order.items.map((item) => (
            <Card key={item.name}>
              <CardContent>
                {item.name} x {item.quantity}
              </CardContent>
            </Card>
          ))}
        </Box>
      </AccordionDetails>
      <AccordionActions>
        <ButtonDeleteOrder order={order} />
      </AccordionActions>
    </Accordion>
  );
}

export default PersonnalOrderAccordion;
