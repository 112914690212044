import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "../../api/axiosapi";

const initialState = {
  loading: false, // To indicate waiting for response from server and whats appening in the background
  error: "", // To indicate error message
};

export const fetchSendSignupMail = createAsyncThunk(
  "users/fetchCreateUser",
  async (mailInformations) => {
    const response = await axiosPrivate.post(
      "/api/mailservice/signupmail",
      mailInformations
    );
    return response.data;
  }
);

const mailSlice = createSlice({
  name: "mail",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSendSignupMail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSendSignupMail.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(fetchSendSignupMail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default mailSlice.reducer;
