import React from "react";
import { Routes, Route } from "react-router-dom";
import "./Assets/Styles/css/normalize.css";
import "./Assets/Styles/css/prefixed/style.css";
import AuthForm from "./Components/Auth/AuthForm";
// import Unauthorized from "./Components/Auth/Unauthorized";
import Footer from "./Components/Layout/Footer";
// import CreateActualityForm from "./Components/Gestion/Actuality/CreateActualityForm";
import Header from "./Components/Layout/Header";
import Home from "./Components/Layout/Home";
import NotFound from "./Components/Errors/NotFound";
// import Gestion from "./Components/Gestion/Gestion";
// import UserPannel from "./Components/Users/UserPannel";
import Visitor from "./Components/Layout/Visitor";
// import Reglement from "./Components/Presentation/Reglement";
// import Reservations from "./Components/Reservations/Reservations";
import UserParameters from "./Components/Users/UserParameters";
// import ObjectView from "./Components/Objects/ObjectView";
import AgendaView from "./Components/AgendaView";
import Maintenance from "./Components/Maintenance/Maintenance";
// import ReservationsPannel from "./Components/Reservations/ReservationsPannel";

function App() {
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />}>
          <Route path="/" element={<Visitor />} />
          <Route path="/agenda" element={<AgendaView />} />
          <Route path="/parametres" element={<UserParameters />} />
          <Route path="/auth" element={<AuthForm />} />
          <Route path="/*" element={<Maintenance />} />
          {/* 
            <Route path="/gestion" element={<Gestion />}>
            <Route path="/gestion/users" element={<UserPannel />} />
            <Route path="/gestion/users/view/:id" element={<UserPannel />} />
            <Route path="/gestion/objects" element={<ObjectView />} />
            <Route
              path="/gestion/actuality"
              element={<CreateActualityForm />}
            />
            <Route
              path="/gestion/reservation"
              element={<ReservationsPannel />}
            />
            <Route
              path="/gestion/reservation/:id/edit"
              element={<UserPannel />}
            />
          </Route>
          <Route path="/reservations" element={<Reservations />} />
          <Route path="/*" element={<NotFound />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/reglement" element={<Reglement />} />
        */}
        </Route>
        {/* catch all */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
