import React from "react";

function AgendaView() {
  return (
    <div className="text-black text-2xl flex justify-center items-center w-full h-full">
      Page en construction
    </div>
  );
}

export default AgendaView;
