/* eslint-disable react/jsx-props-no-spreading */
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  FormControl,
  Slide,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLogin } from "../../data/slices/authSlice";
import useAuth from "../Hooks/useAuth";

const authBoxStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
};

const authFormStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  padding: "20px",
  width: "100%",
  maxWidth: "400px",
  "& .inputBox": {
    position: "relative",
    width: "100%",
    "& input": {
      width: "100%",
    },
  },
};

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function AuthForm() {
  const dispatch = useDispatch();
  const loginResponse = useSelector((state) => state.auth.response);
  const loginError = useSelector((state) => state.auth.error);
  const { setAuth, auth } = useAuth();
  const [isLogin, setIsLogin] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState({
    open: false,
    Transition: SlideTransition,
  });

  const openErrorAlert = (Transition) => {
    setFeedbackOpen({
      open: true,
      Transition,
    });
  };

  async function handleClick() {
    setIsLogin(true);
    const currrentPassword = document.getElementById("password").value;
    const currentUsername = document.getElementById("username").value;
    await dispatch(
      fetchLogin({
        username: currentUsername,
        password: currrentPassword,
      })
    );
    setIsLogin(false);
  }

  useEffect(() => {
    if (loginResponse?.token !== "") {
      setAuth({
        userId: loginResponse?.userId,
        username: document.getElementById("username").value,
        password: document.getElementById("password").value,
        role: loginResponse?.role,
        accessToken: loginResponse?.token,
      });
    } else {
      openErrorAlert(SlideTransition);
    }
  }, [loginResponse]);

  useEffect(() => {
    if (loginError !== null && loginError !== "") {
      openErrorAlert(SlideTransition);
    }
  }, [loginError]);

  const handleClose = () => {
    setFeedbackOpen({
      open: false,
      Transition: SlideTransition,
    });
  };

  return (
    <Container component="section" className="auth__wrapper">
      {auth !== undefined && auth.role !== undefined ? (
        <Box className="auth__form" sx={authBoxStyle} component="article">
          <Alert variant="filled" severity="success">
            <Typography
              variant="body1"
              component="h1"
              className="auth__confirmation"
            >
              Vous êtes connecté.
            </Typography>
          </Alert>
        </Box>
      ) : (
        <Box className="auth__form" sx={authBoxStyle} component="article">
          <FormControl sx={authFormStyle}>
            <div className="error" />
            <div className="inputBox">
              <input type="text" id="username" required />
              <label htmlFor="username">Utilisateur</label>
            </div>
            <div className="inputBox">
              <input type="password" id="password" required />
              <label htmlFor="password">Mot de passe</label>
            </div>
            <Button
              variant="contained"
              className="button"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                handleClick();
              }}
            >
              {isLogin === true ? "Connexion en cours..." : "Connexion"}
            </Button>
            <Alert severity="info" variant="standard">
              <AlertTitle variant="body2" component="p">
                Mot de passe oublié ? Merci de contacter un administrateur.
              </AlertTitle>
            </Alert>
          </FormControl>
        </Box>
      )}
      <Snackbar
        open={feedbackOpen.open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={feedbackOpen.Transition}
        key={feedbackOpen.Transition.name}
        autoHideDuration={6000}
        onClose={() => handleClose()}
      >
        <Alert onClose={() => handleClose()} variant="filled" severity="error">
          <AlertTitle>Erreur de connexion</AlertTitle>
          {loginError !== null
            ? "Mot de passe ou nom d'utilisateur incorrect."
            : "Une erreur est survenue."}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AuthForm;
