import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosPrivate } from "../../api/axiosapi";

const initialState = {
  orderTicket: {
    startDate: "",
    endDate: "",
    items: [],
  },
  orderError: "",
  sendOrderStatus: false,
  personnalOrder: [],
  loading: false,
  loadReservationsFromDateRange: false,
  reservationsFromDateRange: [],
  loadAllReservations: false,
  allReservations: [],
};

// Generates pending, fullfilled and rejected actions
export const fetchSendReservation = createAsyncThunk(
  "order/sendOrder", // Name of the action
  async (orderTicket) => {
    const response = await axiosPrivate.post(
      "/api/reservation/sendReservation",
      orderTicket
    );
    return response.data;
  }
);

export const fetchGetReservationByUser = createAsyncThunk(
  "order/getReservationByUser", // Name of the action
  async (userId) => {
    const response = await axiosPrivate.get(
      `/api/reservation/getReservationByUser/${userId}`
    );
    return response.data;
  }
);

export const fetchDeleteReservationById = createAsyncThunk(
  "order/deleteReservationById", // Name of the action
  async (id) => {
    const response = await axiosPrivate.delete(
      `/api/reservation/deleteReservationById/${id}`
    );
    return response.data;
  }
);

export const fetchGetReservationBetweenDateRange = createAsyncThunk(
  "order/getReservationBetweenDateRange", // Name of the action
  async (dateRange) => {
    const response = await axiosPrivate.get(
      `/api/reservation/getReservationBetweenDateRange/${dateRange.start}/${dateRange.end}`
    );
    return response.data;
  }
);

export const fetchGetAllReservations = createAsyncThunk(
  "order/getAllReservations", // Name of the action
  async () => {
    const response = await axiosPrivate.get(`/api/reservation/getReservations`);
    return response.data;
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchGetAllReservations.pending, (state) => {
      state.loadAllReservations = true;
    });
    builder.addCase(fetchGetAllReservations.fulfilled, (state, action) => {
      state.allReservations = action.payload;
      state.loadAllReservations = false;
    });
    builder.addCase(fetchGetAllReservations.rejected, (state) => {
      state.loadAllReservations = false;
      state.error = "Une erreur est survenue";
    });
    builder.addCase(fetchSendReservation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSendReservation.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(fetchGetReservationByUser.rejected, (state) => {
      state.loading = false;
      state.error = "Une erreur est survenue";
    });
    builder.addCase(fetchGetReservationByUser.fulfilled, (state, action) => {
      state.personnalOrder = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(fetchGetReservationBetweenDateRange.pending, (state) => {
      state.loadReservationsFromDateRange = true;
    });
    builder.addCase(
      fetchGetReservationBetweenDateRange.fulfilled,
      (state, action) => {
        state.reservationsFromDateRange = action.payload;
        state.loadReservationsFromDateRange = false;
        state.error = "";
      }
    );
    builder.addCase(fetchGetReservationBetweenDateRange.rejected, (state) => {
      state.error = "Pas d'objet disponible pour cette période";
    });
    builder.addCase(fetchSendReservation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
  reducers: {
    setOrderTicker: (state, action) => {
      const startDate = new Date(action.payload.startDate);
      startDate.setHours(startDate.getHours() + 2);

      state.orderTicket = {
        startDate: startDate.toISOString(),
        endDate: new Date(action.payload.endDate).toISOString(),
        items: action.payload.items,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOrderTicker } = orderSlice.actions;
export default orderSlice.reducer;
