import React from "react";
import { Link } from "react-router-dom";

function ParameterButton() {
  return (
    <Link to="/parametres" className="navbar__item">
      <i className="fa-solid fa-user-gear nav__element" />
      <span>Mon compte</span>
    </Link>
  );
}

export default ParameterButton;
