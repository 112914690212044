import { fetchObjects } from "../../data/slices/cartSlice";
import {
  fetchGetReservationBetweenDateRange,
  fetchGetReservationByUser,
} from "../../data/slices/orderSlice";

export default async function useRefreshReservationView(
  dispatch,
  currentOrderTicket,
  userInfo
) {
  await dispatch(fetchGetReservationByUser(userInfo.userId));
  await dispatch(fetchObjects());
  const rangeDate = {
    start: currentOrderTicket.startDate,
    end: currentOrderTicket.endDate,
  };
  await dispatch(fetchGetReservationBetweenDateRange(rangeDate));
}
