import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { store } from "./data/store";
import { AuthProvider } from "./Components/Auth/AuthProvider";

const rootElement =
  document.getElementById("root") || document.createElement("div");
const root = createRoot(rootElement);
root.render(
  <Provider store={store}>
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
    </Router>
  </Provider>
);
