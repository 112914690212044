import { Alert, AlertTitle, Container } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function Presentation() {
  return (
    <Container maxWidth="xl" component="section">
      <Alert severity="info" variant="filled" color="info">
        <AlertTitle>Présentation de l&apos;amicale</AlertTitle>
        <p>
          L&apos;amicale des sapeur-pompiers rempli en premier lieu un rôle
          social de cohesion et d&apos;entraide.
        </p>
        <p>
          Les sapeurs-pompiers élus par leurs camarades, sans conditions de
          catégorie ou de grade, mènent au travers de l’amicale de nombreuses
          activités : Sainte Barbe, bal du 14 Juillet, journées portes
          ouvertes... sans oublier les sections de jeunes sapeurs-pompiers
          (JSP), les compétitions sportives, l&apos;histoire et la conservation
          du patrimoine, les loisirs ou encore les incontournables calendriers.
        </p>
        <p>L&apos;amicale est une association loi 1901.</p>
        <Link to="/reglement">Règlement</Link>
      </Alert>
    </Container>
  );
}

export default Presentation;
