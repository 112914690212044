import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const footerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "100%",
  boxShadow:
    "0px -2px 4px -1px rgba(0,0,0,0.2),0px -4px 5px 0px rgba(0,0,0,0.14),0px -1px 10px 0px rgba(0,0,0,0.12)",
  position: "sticky",
  bottom: 0,
  height: "auto",
  padding: { xs: "0.5rem", lg: "0.5rem 0" },
  backgroundColor: "white",
  "& a": {
    color: "black",
    transition: "all 0.3s ease-in-out",
  },
  "& a:hover": {
    color: "black",
    textDecoration: "none",
    transform: "scale(1.1)",
  },
};

const bottomNavStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  width: "100%",
};

function Footer() {
  return (
    <Container component="footer" sx={footerStyle}>
      <Typography variant="body1" component="p">
        Tous droits réservés © 2023
      </Typography>
      <Typography variant="body1" component="p">
        Amicale des sapeurs-pompiers de Châtellerault
      </Typography>
      <Box sx={bottomNavStyle}>
        <Link to="/mentions-legales">Mentions légales</Link>
        <Link to="/mentions-legales">A propos</Link>
        <Link to="/reglement">Règlement</Link>
      </Box>
    </Container>
  );
}

export default Footer;
