import { Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetReservationByUser } from "../../data/slices/orderSlice";
import PersonnalOrderRow from "../Accordion/PersonnalOrderAccordion";
import useAxiosPrivate from "../Hooks/useAxiosPrivate";

const reservationContainerStyle = {
  display: "flex",
  flex: "0 1 35%",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "space-between",
  gap: "1rem",
  padding: "1rem",
  margin: "1rem",
  boxShadow: "0 0 1rem 0 rgba(0, 0, 0, 0.1)",
};

function PersonnalReservations() {
  const dispatch = useDispatch();
  const axiosPrivate = useAxiosPrivate();
  const authInfo = useSelector((state) => state.auth.response);
  const personnalOrder = useSelector((state) => state.order.personnalOrder);

  const fetchUserReservations = async () => {
    await dispatch(fetchGetReservationByUser(authInfo.userId));
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchUserReservations();

    return () => {
      controller.abort();
    };
  }, [axiosPrivate]);

  const showPersonnalOrder = () => {
    if (personnalOrder.length > 0) {
      return (
        <>
          {personnalOrder.map((order) => (
            <PersonnalOrderRow order={order} key={order.id} />
          ))}
        </>
      );
    }
    return (
      <Typography variant="h6" component="p">
        Vous n&apos;avez pas de réservations en cours.
      </Typography>
    );
  };

  return (
    <Container component="article" sx={reservationContainerStyle}>
      <Typography variant="h5" component="h3">
        Mes réservations
      </Typography>
      {showPersonnalOrder()}
    </Container>
  );
}

export default PersonnalReservations;
