import React from "react";
import { Link } from "react-router-dom";

function HomeButton() {
  return (
    <Link to="/" className="navbar__item">
      <i className="fa-solid fa-house nav__element" />
      <span>Accueil</span>
    </Link>
  );
}

export default HomeButton;
