import React from "react";
import { Link } from "react-router-dom";

function AgendaButton() {
  return (
    <Link to="/agenda" className="navbar__item">
      <i
        className="fa-solid fa-calendar-days nav__element"
        title="Agenda"
        alt="Agenda"
      />
      <span>Agenda</span>
    </Link>
  );
}

export default AgendaButton;
