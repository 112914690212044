import { Box, Container, Typography } from "@mui/material";
import React from "react";
import useAuth from "../Hooks/useAuth";
import PersonnalReservations from "../Reservations/PersonnalReservations";
import EditUserForm from "./EditUserForm";

const parameterContainerStyle = {
  padding: "1rem",
  marginBottom: "1rem",
};

const headerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};
const contentBoxStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

function UserParameters() {
  const { auth } = useAuth();

  return (
    <Container component="section" className="auth__account">
      {auth === undefined || auth.role === undefined ? (
        <h1>Vous n&apos;etes pas autorisé.</h1>
      ) : (
        <Container component="article" sx={parameterContainerStyle}>
          <Box sx={headerStyle}>
            <Typography variant="h4" component="h1">
              Parametres du compte
            </Typography>
            <Typography variant="h5" component="h2">
              Vous pouvez modifier vos parametres ici.{" "}
            </Typography>
          </Box>
          <Box sx={contentBoxStyle}>
            <PersonnalReservations />
            <EditUserForm
              userIdToFetch={auth.userId}
              modificationType="light"
            />
          </Box>
        </Container>
      )}
    </Container>
  );
}

export default UserParameters;
